import React from 'react';
import { ImHome3 } from 'react-icons/im';
import { Link } from 'gatsby';
import Lottie from 'react-lottie';



const InProgress = ({animationData, animationClasses, subtext}) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className="in-progress">
            <div className={animationClasses}>
                <Lottie 
                    options={defaultOptions}
                />
            </div>
            <h4 className="subtitle">{subtext}</h4>
            <Link to="/" className="button button--medium">
                <ImHome3/>
                &nbsp;
                Go Home
            </Link>
        </div>
    )
}

export default InProgress;
