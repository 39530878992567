import React from 'react';
import * as animationData from '../lotties/under-construction.json';
import InProgress from '../components/InProgress';
import Navigation from '../components/Navigation';
import '../scss/main.scss';

export default function Contact() {
    
    return (
        <>
            <Navigation/>
            <InProgress 
                animationClasses="in-progress__animation" 
                subtext="Under Construction"
                animationData={animationData}
            />
        </>
    )
}
